import React from "react";
import { useNavigate } from "react-router-dom";
import './Landingpage.css';
import Tech from './Tech';
import Choose from './choose';
import Section from './Section';
import About from "./About";
import Layout from '../component/Layout/Layout'



const Landingpage = () => {
    const navigate = useNavigate();

    const handleGetInTouch = () => {
        navigate('/contactus');
    };
    return (
           <Layout>
                <div className="landingpage">
                <video src={require('../images/bg.mp4')} autoPlay muted loop class="video-bg" type="video/mp4 " />
                <div className="bg-overlay"></div>
                <div className="home-text">
                <h3>Your Partner in Digital Transfromation </h3>
                <h4>Are you ready to elevate your business with best   </h4>
                <div class="won-container">
                     IT solutions
                <div class="curved-line">
                   <svg viewBox="0 0 100 10" preserveAspectRatio="none">
                   <path d="M0,10 Q50,0 100,10" fill="none" stroke="#FFC72C" stroke-width="3"/>
                   </svg>
                </div>
                </div>
               
                <h1>4Tuners Technologies</h1>
                <p>Specialize in <span className="highlight">Mobile Application</span>, <span className="highlight">Web Application</span> & <span className="highlight">Website Development</span>, bringing your vision to life with precision and innovation.</p>
            
                <button id="btn" onClick={handleGetInTouch}>Get In Touch!</button>
            </div>        
        </div>
        <div>
            <Tech/>
        </div> 
         <div>
            <About/>
        </div>
         
        <div>
            <Section/>
        </div>  
        <div>
            <Choose />
        </div>
        
        </Layout>
        


  
    )
}

export default Landingpage;