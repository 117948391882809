import React from 'react';
import './Section.css'
import Project from './Project';
import Client from './Client';
import Industry from './Industry';
const Section = () => {
  return (
     <section className='gg'>
        <Project />
        <Client />
        <Industry />
     </section>
  );
};

export default Section;
