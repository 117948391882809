import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faLaptop, faShoppingCart, faFileAlt, faShareAlt, faSearch, faPaintBrush, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './Service.css';
import Layout from '../component/Layout/Layout';

const Service = () => {
  return (
    <Layout>
    <div className="containers">
      <div className="wrapper1">
        <h1>Our Service</h1>
        <h4>Welcome to 4Tuners Technologies</h4>
        <p id="pera">Your trusted partner for innovative mobile and web application development. Our diverse range of services is designed to meet your digital needs and grow your business forward. Explore our offerings below:</p>
        <div className="content-box1">
          <div className="card">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" />
            <h2>Mobile Application Development</h2>
            <p>
            Get the tremendous potential of mobile technology with our custom app development services. Using React Native, we create cross-platform mobile applications that offer a seamless experience on both iOS and Android devices. Our focus is on delivering high-performance, feature-rich apps that align with your business objectives.            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faLaptop} size="3x" />
            <h2>Web Application Development</h2>
            <p>
            Engage your audience with sophisticated and responsive web applications. With React JS, we develop dynamic websites that provide an exceptional user experience and are optimized for performance. Our web solutions are there to meet your specific requirements and keep pace with the latest web trends.            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faShoppingCart} size="3x" />
            <h2>E-Commerce Solutions</h2>
            <p>
            We are experts in transforming online stores with our comprehensive e-commerce development services. We specialize in leading platforms such as Magento, WooCommerce, and Shopify to create scalable, secure, and user-friendly e-commerce solutions. Whether you're starting a new business or looking to enhance an existing one, we ensure a smooth shopping experience for your customers.            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faFileAlt} size="3x" />
            <h2>Content Management Systems (CMS)</h2>
            <p>
            Efficiently manage your website’s content with our WordPress development services. We build customized CMS solutions that empower you to easily update and maintain your site. Our WordPress websites are designed for flexibility, usability, and performance, making content management simple and effective.            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faShareAlt} size="3x" />
            <h2>Social Media Management</h2>
            <p>
            Enhance your online presence with our social media management services. We create and execute strategies to boost your brand’s visibility and engagement across various platforms. From content creation to community management, we handle all aspects of your social media to help you connect with your audience effectively.            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faSearch} size="3x" />
            <h2>Search Engine Optimization (SEO)</h2>
            <p>
            Improve your website’s visibility and attract more traffic with our SEO services. We deal in best practices and the latest techniques to optimize your site for search engines, ensuring you rank higher and reach your target audience. Our SEO strategies are designed to enhance your online presence and drive sustainable growth.
            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faPaintBrush} size="3x" />
            <h2>UI/UX Design</h2>
            <p>
            Deliver an exceptional user experience with our design services. Our UI/UX design team focuses on creating intuitive and aesthetically pleasing interfaces that engage users and enhance usability. We combine creativity with functionality to ensure your digital products are both visually appealing and easy to navigate.
            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon icon={faBriefcase} size="3x" />
            <h2>Business Development</h2>
            <p>
            Accelerate your business growth with our expert business development services. We offer strategic planning, market analysis, and partnership building to help you expand your market presence and achieve sustainable growth. Our tailored solutions are designed to meet your unique business needs.
            </p>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default Service;
