import React from 'react';
import phone from '../../images/p.png'
import mail from '../../images/m.png'
import india from '../../images/india.png'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram ,} from '@fortawesome/free-brands-svg-icons';
import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-section contact-info rohan">
          <h2>4Tuners Technologies</h2>
          <p id="techno"> Where Technology Meets Your Aspirations..!!</p>
          <p>
            <img src={india} alt="India Flag" /> <a className="phone" href="https://www.google.com/maps/dir//35,+Ashirwad+plaza,+opp.+Kasturba+girls+school,+Kailash+Park+Colony,+Rajwada,+Indore,+Madhya+Pradesh+452002/@22.7172405,75.8051685,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3962fd76aecc2329:0x125185f22b26c01a!2m2!1d75.8876205!2d22.7172698?entry=ttu"> 35, Ashirwad Plaza, 2nd Floor,
            Rajwada,indore (M.P)</a>
          </p>
          <p>
            <img src={phone} alt="Phone Icon" /><a href="tel:+91 97556-41042npm"> +91 97556-41042</a>
          </p>
         
          <p>
           {/* /* <img src={mail} alt="Email Icon" /><a href="mailto:ashish.4tuners@gmail.com"> ashish.4tuners@gmail.com</a>*\ */}
          </p>
          <p>
            <img src={mail} alt="Email Icon" /><a href="mailto:sales@4tunerstech.com">sales@4tunerstech.com</a>
          </p>
          <div className='social iconblock'>
          <a href="https://www.instagram.com/4tuners_technologies/?igshid=MzRlODBiNWFlZA%3D%3D">  <FontAwesomeIcon icon={faInstagram} size="2x" id="icon" style={{ margin: '0 10px' }} /></a>
          <a href="https://www.instagram.com/4tuners_technologies/?igshid=MzRlODBiNWFlZA%3D%3D">  <FontAwesomeIcon icon={faLinkedin} size="2x" id="icon" style={{ margin: '0 10px' }} /></a>

    </div>
        </div>
        <div className="footer-section services">
          <h3 id="services">Services</h3>
          <ul>
            <li>Mobile Application</li>
            <li>Web Application</li>
            <li>E-Commerce Solution</li>
            <li>Content Management System</li>
            <li>Social Media Management</li>
            <li>Search Engine Optimization</li>
            <li>UI/UX Design</li>
            <li>Business Development</li>
          </ul>
        </div>
        <div className="footer-section technologies">
          <h3 id="services">Technologies</h3>
          <ul>
            <li>React Native</li>
            <li>React JS</li>
            <li>Mongo DB</li>
            <li>WordPress</li>
            <li>Ecommerce Platform</li>
            
          </ul>
        </div>
        {/* <div className="footer-section product iconnone">
          <h3>Follow Us</h3>
          <div className='social'>
      <FontAwesomeIcon icon={faLinkedin} size="2x" id="icon" style={{ margin: '0 10px' }} />
      <FontAwesomeIcon icon={faInstagram} size="2x" id="icon" style={{ margin: '0 10px' }} />
      <FontAwesomeIcon icon={faFacebook} size="2x" id="icon" style={{ margin: '0 10px' }} />

    </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
