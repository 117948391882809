export const navItems = [
    {
      id: 1,
      title: "Home",
      path: "/",
      cName: "nav-item",
    },
    {
      id: 2,
      title: "Industries",
      path: "/industries",
      cName: "nav-item",
    },
    {
      id: 3,
      title: "Services",
      path: "/services",
      cName: "nav-item",
      subItems: [
        {
          id: 1,
          title: "Moblie Application",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 2,
          title: "Web Application",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 3,
          title: "E-commerce Solution",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 4,
          title: "Content Management System",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 5,
          title: "Social Media Management",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 6,
          title: "Search Engine Optimization",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 7,
          title: "UI/Ux Design",
          path: "/services",
          cName: "submenu-item1",
        },
        {
          id: 8,
          title: "Business Development",
          path: "/services",
          cName: "submenu-item1",
        },
      ],
    },
    {
      id: 4,
      title: "Company",
      path: "/whychooseus",
      cName: "nav-item",
      subItems: [
        {
          id: 1,
          title: "About US",
          path: "/aboutus",
          title: "About US",
    
          cName: "submenu-item2",
        },
        {
          id: 2,
          title: "Why Choose Us",
          path: "/whychooseus",
          cName: "submenu-item2",
        },
      ],
    },
    {
      id: 5,
      title: "Technology",
      path: "/technology",
      cName: "nav-item",
      subItems: [
        {
          id: 1,
          title: "React Native",
          path: "/technology",
          cName: "submenu-item3",
        },
        {
          id: 2,
          title: "React JS",
          path: "/technology",
          cName: "submenu-item3",
        },
        {
          id: 3,
          title: "Mongo DB",
          path: "/technology",
          cName: "submenu-item3",
        },
        {
          id: 4,
          title: "WordPress",
          path: "/technology",
          cName: "submenu-item3",
        },
        {
          id: 5,
          title: "E-Commerce Platfrom",
          path: "/technology",
          cName: "submenu-item3",
        },
      ],
    },
  ];
  