import React from 'react';
import './Industry.css';
import pharma from '../images/pharma.png'; // Add your image imports here
import banking from '../images/banking.png';
import education from '../images/ee.png';
import serviceDelivery from '../images/serviceDelivery.png';
import logistics from '../images/logistics.png';
import realEstate from '../images/realEstate.png';
import eCommerce from '../images/eCommerce.png';
import hotel from '../images/hotel.png';
import gaming from '../images/gaming.png';
import health from '../images/health.png';
import media from '../images/media.png';
import agriculture from '../images/agriculture.png';
import pro from '../images/pro.png';


function Industry() {
  const categories = [
    { img: pharma, label: "Pharmaceuticals" },
    { img: banking, label: "Banking & NBFC" },
    { img: education, label: "Education" },
    { img: serviceDelivery, label: "Service delivery app" },
    { img: logistics, label: "Logistics" },
    { img: realEstate, label: "Real Estate" },
    { img: eCommerce, label: "E-Commerce" },
    { img: hotel, label: "Hotel Industry" },
    { img: gaming, label: "Gaming Industry" },
    { img: health, label: "Health & Wellness" },
    { img: media, label: "Media & Entertainment" },
    { img: agriculture, label: "Agriculture Industry" },
    { img: pro, label: "Professional Services" },

  ];

  return (
    <div>
    <h1 className="heading1">INDUSTRIES WE SERVE</h1>
    <div className="app">
      <div className="marquee">
        <div className="marquee-content">
          {categories.map((category, index) => (
            <div key={index} className="grid-item">
              <img src={category.img} alt={category.label} className="icon"/>
              <p>{category.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Industry;