import React from 'react';
import './MyPage.css';
import reactnative from '../images/reactnative.jpg';
import reactjsimage from '../images/reactjs.jpg';
import MongoDB_image from '../images/mongo.jpg';
import word_press from '../images/wordpress.jpg';
import Ecommerce from '../images/Ecommerce.jpg';
import Layout from '../component/Layout/Layout';

const MyPage = () => {
  return (
    <Layout>
    <section className="tech-section-container">
      <h2 className="tech-headingh2">Technologies</h2>
      
      <div className="tech-content-block tech-left">
        <img src={reactnative} alt="React native" className="tech-content-image1" />
        <div className="tech-block-container">
          <h2 className="tech-block-heading"><u>React Native Development</u></h2>
          <p className="tech-description">Harness the power of React Native to create high-performance, cross-platform mobile applications that offer a native experience on both iOS and Android devices. Our React Native development services are designed to provide scalable and efficient solutions that accelerate your time-to-market while maintaining a seamless user experience. Whether you're looking to build a new app from scratch or enhance an existing one, our team is dedicated to delivering results that align with your business goals.</p>
        </div>
      </div>
      
      <div className="tech-content-block tech-right">
        <img src={reactjsimage} alt="reactjs" className="tech-content-image2"/>
        <div className="tech-block-container">
          <h2 className="tech-subheading"><u>React JS Development</u></h2>
          <p className="tech-description">Unlock the full potential of your web applications with our React JS development expertise. React JS allows us to create dynamic, high-performing user interfaces with ease. Whether you need a single-page application or a complex web platform, we employ React JS to build responsive and interactive interfaces that enhance user engagement and streamline your web presence.</p>
        </div>
      </div>

      <div className="tech-content-block tech-left">
        <img src={MongoDB_image} alt="MongoDB" className="tech-content-image3"/>
        <div className="tech-block-container">
          <h2 className="tech-subheading"><u>MongoDB Solutions</u></h2>
          <p className="tech-description">Our MongoDB solutions provide a powerful, flexible database management system designed to handle modern data challenges. With MongoDB’s NoSQL capabilities, we offer scalable and efficient data storage solutions that ensure optimal performance for your applications. From database design to integration and optimization, our team ensures that your data management infrastructure supports your business objectives effectively.</p>
        </div>
      </div>

      <div className="tech-content-block tech-right">
        <img src={word_press} alt="WordPress" className="tech-content-image4"/>
        <div className="tech-block-container">
          <h2 className="tech-subheading"><u>WordPress Development</u></h2>
          <p className="tech-description">Empower your online presence with our WordPress development services. As a leading content management system, WordPress offers unparalleled flexibility and ease of use. We specialize in creating custom WordPress themes and plugins, optimizing existing websites, and ensuring that your site is secure, fast, and aligned with your brand’s identity. Whether you need a blog, business site, or complex portal, our WordPress solutions cater to all your needs.</p>
        </div>
      </div>
      
      <div className="tech-content-block tech-left">
        <img src={Ecommerce} alt="Ecommerce" className="tech-content-image5"/>
        <div className="tech-block-container">
          <h2 className="tech-subheading"><u>E-Commerce Platform Developmen Technologies</u></h2>
          <p className="tech-description">In the competitive world of E-Commerce, having a robust and user-friendly platform is crucial. We offer comprehensive development services for leading e-commerce platforms including WooCommerce, Shopify, and Magento. From setting up and customizing online stores to integrating payment gateways and optimizing for SEO, we help you create a seamless shopping experience that drives sales and customer satisfaction.</p>
        </div>
      </div>

    </section>
    </Layout>
  );
}

export default MyPage;
