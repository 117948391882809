import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Tech.css';  
import customImg from '../img2/custom_soft.png';
import digitalImg from '../img2/Digital_transformation.png';
import mobileImg from '../img2/mobile.png';
import mvpImg from '../img2/mvp.jpg';
import webImg from '../img2/web_dev.png';

const Tech = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2200, 
    responsive: [
      {
        breakpoint: 1440, // For larger screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024, // For medium-sized screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // For small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="tech-carousel">
      <h2>Exclusive Mobile App & Web Development Company</h2>
      <Slider {...settings}>
        <div className="tech-carousel-item">
          <div className="tech-overlay"></div>
          <img src={mobileImg} alt="Mobile App Development" />
          <div className="tech-content">
            <h3>Mobile App Development</h3>
            <p>We build mobile apps with immersive user experiences.</p>
          </div>
        </div>
        <div className="tech-carousel-item web-development">
          <div className="tech-overlay"></div>
          <img src={webImg} alt="Web Development" />
          <div className="tech-content">
            <h3>Web Development</h3>
            <p>We design, develop and maintain websites & web apps.</p>
          </div>
        </div>
        <div className="tech-carousel-item custom-software">
          <div className="tech-overlay"></div>
          <img src={customImg} alt="Custom Software Development" />
          <div className="tech-content">
            <h3>Custom Software Development</h3>
            <p>Your development partner from conception to creation.</p>
          </div>
        </div>
        <div className="tech-carousel-item digital-transformation">
          <div className="tech-overlay"></div>
          <img src={digitalImg} alt="Digital Transformation" />
          <div className="tech-content digital">
            <h3>Digital Transformation</h3>
            <p>Migrate and modernize your legacy apps.</p>
          </div>
        </div>
        <div className="tech-carousel-item">
          <div className="tech-overlay"></div>
          <img src={mvpImg} alt="MVP Development" />
          <div className="tech-content">
            <h3>MVP Development</h3>
            <p>We Offer World-Class MVP Development Services</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Tech;
