import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landingpage from "./pages/Landingpage";
import About from "./pages/About";
import Project from "./pages/Project";
import Tech from "./pages/Tech";
import MyPage from "./pages/MyPage";
import Section from "./pages/Section";
import Service from "./pages/Service";
import BrowseByIndustriesw from "./pages/BrowseByIndustriesw";
import ContectForm from "./component/From/ContectFrom";
import Industries from './pages/Content';
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import Choose from "./pages/choose";
// import PageNotFound from "./pages/PageNotFound";


function App() {
  return (
    <div>
     
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/project" element={<Project />} />
          <Route path="/tech" element={<Tech />} />
          <Route path="/technology" element={<MyPage />} />
          <Route path="/section" element={<Section />} />
          <Route path="/services" element={<Service />} />
          <Route path="/industries" element={<BrowseByIndustriesw />} />
          <Route path="/industry/:industryIndex" element={<Industries />} />
          <Route path="/contactus" element={<ContectForm />} />
          <Route path="/whychooseus" element={<Choose />} />
          {/* <Route path="#" element={<PageNotFound />} /> */}
        
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;