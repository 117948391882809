import React from 'react';
import './choose.css';
import zoom from '../images/zoom.jpg'; // Ensure the path is correct

function Choose() {
  return (
    <div className="container">
      <div className="first">
        <div className="item-1">
          <i className="fa-solid fa-lightbulb"></i>
          <h3 className="subhead">Expertise</h3>
          <p>Across Competitive Technology our team excels in a range of advanced
            technologies, including React Native, React JS, WordPress,
            E-Commerce platforms, and MongoDB etc.
            Whether you're looking to build a dynamic mobile app, a robust web
            application, or a scalable e-commerce solution, we have the expertise to deliver outstanding results.
          </p>
        </div>
        <div className="item-2">
          <i className="fa-solid fa-puzzle-piece"></i>
          <h3>Customized Solutions for Your Unique Needs</h3>
          <p>We understand that every business is unique, and so are its challenges. our focus is
            to grab your specific requirements, ensuring that our solutions not only meet but exceed
            your expectations. We work closely with you to understand your goals and craft customized strategies
            that align with your vision.
          </p>
        </div>
      </div>
      <div className="image-box-3">
        <img src={zoom} alt="Zoom Effect Image" className="zoom-effect" />
      </div>
      <div className="last">
        <div className="flex-item-4">
          <h1 className="hedline">WHY <span>CHOOSE US?</span></h1>
          <p id="special-paragraph">At 4Tuners Technology, we're not just a software company. We're your technology
            partner dedicated to driving your success. Here's why we stand out;</p>
        </div>
        <div className="flex-item-row">
          <div className="flex-item-5">
            <i className="fa-solid fa-calendar-days"></i>
            <h3>Commitment to Quality and Innovation</h3>
            <p>At 4Tuners Technologies, we're committed to delivering top-notch quality and innovation. Our development
              practices are rooted in industry best standards and our team continuously explores emerging technologies to
              provide you with the best solutions that keep you ahead of the competition.
            </p>
          </div>
          <div className="flex-item-6">
            <i className="fa-solid fa-clock-rotate-left"></i>
            <h3>Dedicated Support and Maintenance</h3>
            <p>Our commitment doesn't end with the delivery of your project. We offer comprehensive support
              and maintenance services to ensure your systems run smoothly and efficiently. Our dedicated support
              team is always ready to address any issues and provide timely solutions, so you can focus on what you do best.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choose;
