import React, { useState, useRef } from 'react';
import './ContectFrom.css'; // Assuming you have a separate CSS file
import Layout from '../Layout/Layout';

const ContectForm = () => {
  const [budget, setBudget] = useState(2700);
  const [budgetValue, setBudgetValue] = useState('$2700');
  const [formError, setFormError] = useState('');
  const formRef = useRef(null); // Create a reference to the form element

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setBudget(value);
    setBudgetValue(`$${value}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError(''); // Reset form error

    const formData = new FormData(event.target);

    // Check if all required fields are filled out
    if (!formData.get('name') || !formData.get('email') || !formData.get('services')) {
      alert('Please fill out all required fields.');
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/submit-form', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.text();
        alert(data);

        // Reset the form after successful submission
        formRef.current.reset();
        setBudget(2700); // Reset budget value
        setBudgetValue('$2700'); // Reset budget display value
      } else {
        setFormError('There was an error submitting the form.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setFormError('There was an error submitting the form.');
    }
  };

  return (
    <Layout>
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Full Name *</label>
            <input type="text" id="name" name="name" placeholder="Enter Your Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input type="email" id="email" name="email" placeholder="contact@colorlib.com" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" name="phone" placeholder="Enter Number Phone" />
          </div>
          <div className="form-group">
            <label htmlFor="services">Needed Services *</label>
            <input type="text" id="services" name="services" placeholder="Needed Services" required />
          </div>
          <div className="form-group">
            <label>What type of products do you sell?</label>
            <div className="radio">
              <input type="radio" id="physical" name="product-type" value="physical" defaultChecked />
              <label htmlFor="physical">Physical Products</label>
            </div>
            <div className="radio">
              <input type="radio" id="digital" name="product-type" value="digital" />
              <label htmlFor="digital">Digital Products</label>
            </div>
            <div className="radio">
              <input type="radio" id="consulting" name="product-type" value="consulting" />
              <label htmlFor="consulting">Services Consulting</label>
            </div>
          </div>
          <div className="form-group budget">
            <label htmlFor="budget">Budget *</label>
            <input
              type="range"
              id="budget"
              name="budget"
              min="0"
              max="40000"
              value={budget}
              className="slider"
              onChange={handleSliderChange}
            />
            <span id="budgetValue">{budgetValue}</span>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" placeholder="Your message here..."></textarea>
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
        {formError && <div className="error-message">{formError}</div>}
      </div>
    </Layout>
  );
};

export default ContectForm;
