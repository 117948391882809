import React from 'react';
import './Project.css';

const Project = () => {
    return (
        <section className="business-stats">
            <div className="stat">
                <h2>3+</h2>
                <p>Years of Experience</p>
                <p>A legacy of excellence IT services.</p>
            </div>
            <div className="stat">
                <h2>70%</h2>
                <p>Increase in Efficiency</p>
                <p>Streamlined operations and improved workflow.</p>
            </div>
            <div className="stat">
                <h2>95%</h2>
                <p>Client Satisfaction Rate</p>
                <p>Trusted by businesses across all sectors for superior service.</p>
            </div>
            <div className="stat">
                <h2>50+</h2>
                <p>Projects Completed</p>
                <p>Delivering cutting-edge solutions for a seamless digital future.</p>
            </div>
        </section>
    );
};

export default Project;
