import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { navItems } from "./NavItems";

function Navbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hidden, setHidden] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const isLandingPage = location.pathname === "/";

  const handleMouseEnter = (id, path) => {
    if (window.innerWidth > 768) {
      setActiveDropdown(id);
      if (id === 2) { // Check if the hovered item is "Industries"
        navigate(path);
      }
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setActiveDropdown(null);
      if (location.pathname === "/industries") {
        navigate(-1); // Navigate back to the previous page
      }
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path, { replace: true });
    window.location.reload(); // Force page reload
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrolled(currentScrollY > 50);

      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setHidden(true);
      } else {
        // Scrolling up
        setHidden(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <nav
      className={`navbar ${scrolled ? "scrolled" : ""} ${hidden ? "hidden" : ""} ${
        isLandingPage ? "transparent" : "solid"
      }`}
    >
      <Link to="/" className="navbar-logo" onClick={closeSidebar}>
        4Tuners Technologies
      </Link>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      <ul className={`nav-items ${sidebarOpen ? "sidebar-open" : ""}`}>
        {navItems.map((item) => (
          <li
            key={item.id}
            className={`nav-item ${item.subItems ? "has-dropdown" : ""}`}
            onMouseEnter={() => handleMouseEnter(item.id, item.path)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation(item.path)}
          >
            <Link to={item.path} onClick={() => handleNavigation(item.path)}>
              {item.title}
            </Link>
            {item.subItems && (
              <ul
                className={`dropdown-menu ${
                  item.id === activeDropdown ? "clicked" : ""
                } ${item.title.toLowerCase()}-submenu`}
              >
                {item.subItems.map((subItem) => (
                  <li key={subItem.id}>
                    <Link to={subItem.path} className="submenu-item" onClick={() => handleNavigation(subItem.path)}>
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <Link to="/contactus">
          <button className="btn" onClick={closeSidebar}>Contact Us</button>
        </Link>
      </ul>
      {sidebarOpen && (
        <div className="sidebar-overlay" onClick={toggleSidebar}></div>
      )}
    </nav>
  );
}

export default Navbar;
