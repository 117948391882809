import React from 'react';
import './About.css';
import ab from '../images/ab.jpg';

const About = () => {
  
    return (
        <div className="about">
            <div className="part1">
              <h1 id='head1'>ABOUT US</h1>
                <div className="line1">
                    <svg viewBox="0 0 100 10" preserveAspectRatio="none">
                        <path d="M0,10 Q50,0 100,10" fill="none" stroke="#FFC72C" strokeWidth="3"/>
                    </svg>
                </div>
                <p id="paregraph1">
                    Welcome to 4Tuners Technologies, where innovation meets excellence. With 10 years of experience, 
                    we are a forward-thinking technology company dedicated to delivering cutting-edge solutions for 
                    businesses and individuals. 
                
                </p> 
                <p id="paregraph2">
                    We, as a leading web development service provider, specialize in creating 
                    customise digital experiences that elevate your online presence.
                </p>
                <p id="paregraph3">
                    Our mission is to make technology 
                    accessible and transformative. Being a leading software development company, we believe that technology 
                    should not be a barrier but a bridge, enabling businesses to thrive and individuals to realize their 
                    digital aspirations. Our commitment to providing top-notch, tailor-made solutions helps our clients 
                    achieve their goals.
                </p>  
                  
               
            </div>
            <div className="part2">
                <img src={ab} alt="About image" className="aboutimg" />
            </div>
        </div>
    );
};

export default About;
