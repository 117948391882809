import React, { useState } from 'react';
import './Client.css';
import go from '../images/go.png';
import cari from '../images/cari.png'; 
import mis from '../images/mis.png';
// import n3 from '../images/n3.png';

const Client = () => {
    const [expanded, setExpanded] = useState({
        govind: false,
        sher: false,
        nisha: false,
        nidhi: false
    });

    const toggleContent = (key) => {
        setExpanded(prev => ({ ...prev, [key]: !prev[key] }));
    };

    return (
        <section className="client">
            <div className="containeer">
                <h1 className="title" id="client">Words from <span>Client</span></h1>
                <div className="testimonial-container">
                    <div className="testimonial-box">
                        <div className="testimonial-header">
                            <div>
                                <h3>Govind B</h3>
                                <h2>Vrindavan Tour Guide</h2>
                            </div>
                            <img src={go} alt="Profile Image" className="profile-image" />
                        </div>
                        <div className="testimonial-content">
                            <p>
                                {expanded.govind ? 
                                    "I couldn't be more pleased with the exceptional website that Ayush and his team created for my tour and guide business in Vrindavan city. Their dedication and expertise in web development truly transformed my business and helped us reach a broader audience. Ayush's team took our vision of making Vrindavan more accessible and appealing to tourists and turned it into a reality. The website they designed is not only visually stunning but also highly functional, making it easy for travelers to plan their visit to this religiously significant city." 
                                    : 
                                    "I couldn't be more pleased with the exceptional website that Ayush and his team created for my tour and guide business in Vrindavan city.Their dedication and expertise in web development truly transformed my business and helped us reach a broader audience...."}
                            </p>
                            <div className="read-more-container">
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleContent('govind'); }} className="read-more-link">
                                    {expanded.govind ? 'Read Less' : 'Read More'}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-box">
                        <div className="testimonial-header">
                            <div>
                                <h3>Sher</h3>
                                <h2>Caribbeaneaze</h2>
                            </div>
                            <img src={cari} alt="Profile Image" className="profile-image" />
                        </div>
                        <div className="testimonial-content">
                            <p>
                                {expanded.sher ? 
                                    "I had the pleasure of working with 4Tuners Technologies on a project to create a web application, and I'm absolutely delighted with the results I've seen so far. Their team demonstrated exceptional professionalism and technical expertise throughout the entire process. I highly recommend 4Tuners Technologies for anyone in need of top-notch app and web development. Their dedication, expertise, and excellent communication make them a standout choice in this industry. It was a pleasure to collaborate with them, and I look forward to future projects." 
                                    : 
                                    "I had the pleasure of working with 4Tuners Technologies on a project to create a web application, and I'm absolutely delighted with the results I've seen so far.Their team demonstrated exceptional professionalism and technical expertise throughout the entire process...."}
                            </p>
                            <div className="read-more-container">
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleContent('sher'); }} className="read-more-link">
                                    {expanded.sher ? 'Read Less' : 'Read More'}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-box">
                        <div className="testimonial-header">
                            <div>
                                <h3>Nisha Kamra</h3>
                                <h2>MiSiactitas</h2>
                            </div>
                            <img src={mis} alt="Profile Image" className="profile-image" />
                        </div>
                        <div className="testimonial-content">
                            <p>
                                {expanded.nisha ? 
                                    "The team has been superb in helping getting my apps prepared. Very knowledgeable from not only technical standpoint, but also apply business logic. The team has real integrated capabilities from architecture, design, development and product management. They work not just as taking instructions, but work as thought partners to ensure the end result is feasible, optimal and functional. Thank you team for your amazing support." 
                                    : 
                                    "The team has been superb in helping getting my apps prepared. Very knowledgeable from not only technical standpoint, but also apply business logic.The team has real integrated capabilities from architecture, design, development and product management...."}
                            </p>
                            <div className="read-more-container">
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleContent('nisha'); }} className="read-more-link">
                                    {expanded.nisha ? 'Read Less' : 'Read More'}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-box">
                        <div className="testimonial-header">
                            <div>
                                <h3>Nidhi Chandak</h3>
                                <h2>N3collecties.nl</h2>
                            </div>
                            <img src={"https://n3collecties.nl/static/version1724267301/frontend/Sm/destino/nl_NL/images/logo.svg" } alt="Profile Image2" className="profile-image2" />
                        </div>
                        <div className="testimonial-content">
                            <p>
                                {expanded.nidhi ? 
                                    "I had the pleasure of working with Ashish Vyas and his team at 4Tuners for the development of our Dutch website, N3collecties.nl. They took the time to thoroughly understand all our requirements and delivered the project exactly as we envisioned. Their dedication and professionalism were evident throughout, ensuring that the work was completed on time and to the highest standards of quality. The final product exceeded our expectations, and the website's functionality and design have received excellent feedback. I highly recommend 4Tuners for their commitment to delivering exceptional results. We look forward to collaborating with them again in the future!   Nidhi Chandak, CEO, N3Collecties.nl, NRcollecties.nl, Amsterdam (The Netherlands)" 
                                    : 
                                    "I had the pleasure of working with Ashish Vyas and his team at 4Tuners for the development of our Dutch website, N3collecties.nl. They took the time to thoroughly understand all our requirements and delivered the project exactly as we envisioned...."}
                            </p>
                            <div className="read-more-container">
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleContent('nidhi'); }} className="read-more-link">
                                    {expanded.nidhi ? 'Read Less' : 'Read More'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Client;
