import React from 'react';

import './Content.css';

import ecommerceImage from '../images_side/ecom.jpg';
import hotelImage from '../images_side/hotel.jpg';
import hospitalityImage from '../images_side/hosp.jpg';
import financeImage from '../images_side/bank.jpg';
import realEstateImage from '../images_side/real.jpg';
import agriculture from '../images_side/agri.jpg';
import professional from '../images_side/professional.jpg';
import Health from '../images_side/wellness.jpg';
import education from '../images_side/ed.jpg';
import media from '../images_side/med.jpg';
import service from '../images_side/service.jpg';
import logistics from '../images_side/log.jpg';

const industriesData = [
  { 
    
    name: 'E-Commerce',
    description: 'In the fast-paced world of online retail, we provide robust e-commerce solutions that enhance user experience, streamline operations, and drive growth. Our expertise includes developing intuitive shopping platforms, optimizing payment gateways, and integrating advanced analytics to boost your online presence.',
    image:ecommerceImage,
},
  {
    name: 'Hotel & Restaurants',
    description: 'Elevate your hospitality business with our groundbreaking technology solutions. From reservation systems to property management, we offer tools designed to improve guest experiences, optimize operations, and manage bookings efficiently.',
    image:hotelImage,
},
  {
    name: 'Hospitality',
    description: 'Our solutions for the hospitality sector are to streamline operations, enhance guest interactions, and increase efficiency. We focus on integrating systems that support seamless guest services, booking management, and operational analytics.',
    image:hospitalityImage,
},
  {
    name: 'Finance, Banking & NBFC',
    description: 'We provide innovative solutions for the finance and banking sectors, including secure transaction processing, digital banking platforms, and compliance management systems. Our technology ensures reliability, security, and efficiency for financial operations and customer interactions.',
    image:financeImage,
},
  {
    name: 'Real Estate',
    description: 'Transform the real estate industry with our advanced tools for property management, client relationship management, and real estate analytics. We develop platforms that simplify transactions, enhance property visibility, and improve client engagement.',
    image:realEstateImage,
},
  {
    name: 'Agriculture Industry',
    description: 'Our technology solutions for the agriculture sector focus on improving productivity and efficiency. From farm management systems to supply chain optimization, we provide tools that support precision farming, resource management, and market connectivity.',
    image:agriculture,
},
  {
    name: 'Professional Services',
    description: 'For businesses in the professional services sector, we offer customized solutions that streamline project management, enhance client interactions, and optimize service delivery. Our technology supports various professional services, including consulting, legal, and engineering.',
    image:professional,
},
  {
    name: 'Health & Wellness',
    description: 'Enhance patient care and operational efficiency in the health and wellness sector with our tailored solutions. We provide technologies for electronic health records, telemedicine, appointment scheduling, and wellness tracking to support both providers and patients.',
    image:Health,
},
  {
    name: 'Education',
    description: 'Our education technology solutions facilitate engaging and effective learning environments. We offer e-learning platforms, virtual classrooms, and administrative tools designed to support educators and students in achieving educational excellence.',
    image:education,
},
  {
    name: 'Media & Entertainment',
    description: 'In the media and entertainment industry, we deliver solutions that enhance content management, distribution, and viewer engagement. Our technology supports streaming services, content creation, and audience analytics to drive success in this competitive field.',
    image:media,
},
  {
    name: 'Service Delivery Apps',
    description: 'For businesses focusing on service delivery, we provide robust app solutions that streamline scheduling, customer interactions, and service management. Our technology ensures seamless communication and efficient service delivery.',
    image:service,
},
  {
    name: 'Logistics',
    description: 'Optimize your logistics operations with our advanced technology solutions. From fleet management to supply chain analytics, we offer tools designed to improve efficiency, reduce costs, and enhance overall logistics performance.',
    image:logistics,
},
];

const Industry = ({ name, description, image, isLeftAligned }) => (
    <div className={`content-block ${isLeftAligned ? 'left' : 'right'}`}>
      <img src={image} alt={name} className={`content-image ${isLeftAligned ? 'content-image-left' : 'content-image-right'}`} />
      <div className="block-container">
        <h2 className="block-heading"><u>{name}</u></h2>
        <p className="description">{description}</p>
      </div>
    </div>
  );
  
  const Industries = ({ selectedIndustryIndex }) => (
    <section className="section-container">
      <h2 className="heading">Industries We Serve</h2>
      {industriesData.map((industry, index) => (
        <Industry
          key={index}
          name={industry.name}
          description={industry.description}
          image={industry.image}
          isLeftAligned={index % 2 === 0} // Alternate alignment
        />
      ))}
    </section>
  );
  
  
  export default Industries;
