// Updated JSX

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './BrowseByIndustries.css';
import healthcareImage from '../images_1/healthcare.jpg'
import ecommerceImage from '../images_1/ecomerce.jpg';
import transportImage from '../images_1/transport.jpg';
import socialNetworkingImage from '../images_1/social.jpg';
import realEstateImage from '../images_1/realestate.jpg';
import educationImage from '../images_1/education.jpg';
import financeImage from '../images_1/banking.jpg';
import travelImage from '../images_1/travel.jpg';
import retailImage from '../images_1/manufacturing.jpg';
import oilImage from '../images_1/oil.jpg';
import mediaImage from '../images_1/media.jpg';
import onDemandImage from '../images_1/ondemand.jpg';
import Layout from '../component/Layout/Layout';

const industries = [
  { name: 'Health & Wellness', imageClass: 'bg-healthcare-custom', image: healthcareImage },
  { name: 'E-Commerce', imageClass: 'bg-ecommerce-custom', image: ecommerceImage },
  { name: 'Logistics', imageClass: 'bg-transport-custom', image: transportImage },
  { name: 'Professional Services', imageClass: 'bg-social-custom', image: socialNetworkingImage },
  { name: 'Real Estate', imageClass: 'bg-realestate-custom', image: realEstateImage },
  { name: 'Education', imageClass: 'bg-education-custom', image: educationImage },
  { name: 'Banking, Finance & NBFC', imageClass: 'bg-finance-custom', image: financeImage },
  { name: 'Hospitality', imageClass: 'bg-travel-custom', image: travelImage },
  { name: 'hotel & Restaurent', imageClass: 'bg-retail-custom', image: retailImage },
  { name: 'Agriculture Industry', imageClass: 'bg-oil-custom', image: oilImage },
  { name: 'Media & Entertainment', imageClass: 'bg-media-custom', image: mediaImage },
  { name: 'Service Delivery Apps', imageClass: 'bg-ondemand-custom', image: onDemandImage }
];

const BrowseByIndustries = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndustryIndex, setSelectedIndustryIndex] = useState(null);
  const navigate = useNavigate();

  const handleIndustryClick = (index) => {
    setSelectedIndustryIndex(index);
    setTimeout(() => {
      navigate(`/industry/${index}`);
    }, 500);
  };

  return (
    <Layout>
      <div className="industry-container">
        <div className="head-custom">Industries We Serve</div>
        <div className={`bg-dark-custom ${hoveredIndex !== null ? industries[hoveredIndex].imageClass : ''}`}>
          <div className="fullNavi_top-custom">
            <div className="fullNavi_top_left-custom">
              <ul className="navList-custom">
                {industries.map((industry, index) => (
                  <li
                    key={index}
                    className={`fullNav_menuBox-custom ${hoveredIndex === null ? '' : (hoveredIndex === index ? 'hovered' : 'hidden')}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleIndustryClick(index)}
                  >
                    <img src={industry.image} alt={industry.name} className="industry-image-custom" />
                    <div className="fullNav_menuBox_txt-custom">
                      <div className="menuBoxHeading-custom">{industry.name}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className={`slide-up-custom ${selectedIndustryIndex !== null ? 'active' : ''}`}>
          {/* Content of the sliding page (optional, or leave empty for transition effect only) */}
        </div>
      </div>
    </Layout>
  );
};

export default BrowseByIndustries;
